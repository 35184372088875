body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: fit-content;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
}

td.align-left {
  text-align: left;
}
td.align-center {
  text-align: center;
}
td.align-right {
  text-align: right;
}

.App {
  text-align: center;
}

.App-header {
  height: 150px;
  padding: 20px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.appView {
  width: 100vw !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
@media only screen and (max-width: 767px) {
  .ui.container {
    margin-left: unset !important;
    margin-right: unset !important;
  }
}

.pageView {
  height: calc(100vh - 64px);
  width: 100% !important;
  margin-top: 64px;
  padding: 2px;
  padding-top: 24px;
  padding-bottom: 36px;
  overflow-y: scroll;
}

div.pageContent {
  max-width: 1300px;
  width: 100%;
  padding-inline: 16px;
  margin: auto;
}

.heatmap-item {
  font-size: 10px;
}

.aigame-box {
  width: 100%;
  height: 540px;
}

.link-lookalike-button {
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.plain-left-list {
  display:inline-block;
  padding-left:0px;
  list-style-type:'';
}
.plain-left-list li {
  padding-bottom: 8px;
  text-align:left;
}
.collapsing-list {
  display: inline-flex;
  padding-left:0px;
  list-style-type:'';
}
.collapsing-list li {
  padding-right: 16px;
  width:max-content;
  text-align:left;
}
@media screen and (max-width: 800px) {
  .collapsing-list {
    display:inline-block;
  }
}

.overviewToggles {
  display:block;
  height:96px;
  margin-left:0px;
}
@media screen and (max-width: 800px) {
  .overviewToggles {
    height:fit-content;
    margin-bottom: unset;
  }
}

.welcomeTopMenus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.welcomeTopLeft {
  display:block;
  position:initial;
  margin-left:0px; 
}

.welcomeTopRight {
  display:block;
  position:inherit;
  right: 0px;
  top: 0px;
}

hr.title {
  margin-block: 12px;
}

hr.chapter {
  margin-block: 12px;
}

hr.section {
  margin-block: 12px;
}

.tickerOverviewSection {
  padding-bottom: 32px;
}

.tickerOverviewHeaderInteracts {
  margin-top: -98px;
}

/* ----------------------------------------------------------------------------------------- */
/* BURGER_AT_WIDTH */
@media screen and (max-width: 1400px) {
  .welcomeTopRight {
    position:relative;
  }

  .welcomeTopLeft {
    position:relative;
    height:fit-content;
    top: 0%;
  }

  .tickerOverviewHeaderInteracts {
    margin-top: unset;
  }
}

/* ----------------------------------------------------------------------------------------- */
/* Small devices */
@media screen and (max-width: 640px) {
  .aigame-box {
    width: 320px;
  }
}
