.marketSelectorContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 16px;
  width: 100%;
  padding: 20px;
  margin-bottom: 24px;
}

.marketSelector-buttonFill {
  width: 180px;
  height: 28px;
  text-align: center;
  margin-top: -10px;
  margin-bottom: 6px;
}

.marketSelectorButton {
  display: inline-block;
  padding: 16px 24px;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 12px;
  margin: 12px;
}

.marketSelectorButton:active  {
  transform: translateY(4px);
}

.marketSelectorButton.selected  {
  transform: translateY(4px) !important;
}
