.c_ListSelector {
  width: 300px;
  display: 'flex';
  align-items: 'left';
  justify-content: 'left';
  margin: auto;
  font-size: 14px;
  font-weight: 530;
}
.c_ListSelector.list {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}
.c_ListSelector.listLabel {
  padding-left: 6px;
}
.c_ListSelector.listElement {
  width: 100%;
  height: 32px;
  margin: 0, 0;
  padding: 4px;
  display: flex;
  align-items: center;
}
.c_ListSelector.listElement.bg-head {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.c_ListSelector.listElement.bg-1.last {
  border-bottom-left-radius: 6px; 
  border-bottom-right-radius: 6px;
}
.c_ListSelector.listElement.bg-2.last {
  border-bottom-left-radius: 6px; 
  border-bottom-right-radius: 6px;
}
.c_ListGrouped {
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  width: 100%;
  min-height: 220px;
  padding: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
}
.c_ListGrouped-item {
  margin: 8px;
  cursor: pointer;
}

.c_Heatmap-container {
  display: flex;
  justify-content: center;
}
.c_Heatmap-calls-puts {
  display: inline-flex;
  flex-wrap: wrap;
  width: fit-content;
  height: fit-content;
  margin-block: auto;
}
.c_Heat-map {
  width: fit-content;
  margin-inline-end: 12px;
}
.c_Heat-yLabels {
  margin-inline-end: 12px;
}
.c_Heat-top5 {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  justify-content: center;
}
.c_Heat-top5 ol {
  padding-inline: 24px;
}
.c_Heat-top5 h2 {
  text-align: left;
}
.c_Heat-top5 {
  width: fit-content;
}
.c_Heat-top5 li {
  width: 180px;
}
.c_Heat-warning {
  height:26px;
  margin-bottom:12px;
  margin-top:24px;
  font-size:18px;
  font-weight:bold;
  color:red;
}
@media screen and (max-width: 800px) {
  .c_Heat-warning {
    height:52px;
  }
}
@media screen and (max-width: 480px) {
  .c_Heat-warning {
    height:78px;
  }
}

table.even-cells, table.even-cells thead tr th {
  border-collapse: separate;
}
table.even-cells tbody {
  height:700px;
  display:block;
  overflow:auto;
}
table.even-cells thead, table.even-cells tbody tr {
  display:table;
  width:100%;
  table-layout:fixed;
}
table.even-cells thead {
  width: calc( 100% - 1.5em );
}
table.even-cells {
  width: 100%;
  max-width: max-content;
}
table.no-scroll thead {
  width: fit-content!important;
  min-width: 100%;
}
table.no-scroll tbody {
  height: fit-content!important;
}
table.even-cells, table.even-cells thead tr th {
  border-collapse: separate;
}

.full-cells {
  display: contents;
  width: 100%;
  overflow-x: auto;
  margin-top: 4px;
}
.full-cells table {
  display: block;
}
.full-cells table tbody {
  max-height: 700px;
  overflow-y:auto;
  text-align: center;
}
.full-cells th {
  padding: 0 8px;
  text-align: center;
}
.full-cells th:first-child > div {
  border-left: none;
  padding-left: 7px;
}
.full-cells th > div {
  line-height: 1.875em;
  margin-left: -6px;
  padding-left: 5px;
  position: absolute;
  top: 0;
}
.full-cells td {
  padding: 5px;
}

.contractBooklet {
  display: block;
  width: max-content;
  height: max-content;
  min-width: 300px;
  min-height: 256px;
}

.contract-info {
  display:block;
  width: 300px;
  padding-block: 18px;
  min-height: 360px;
}
.contract-info tbody {
  width: 100%;
}
.contract-info tbody tr td {
  width: 50%;
  box-sizing: content-box;
}
.empty-contract-info {
  display:block;
  width: 300px;
  height: 595px;
  padding: 18px;
}

form.credit-form {
  display: inline-grid;
  margin: auto;
  text-align: right;
}

canvas.probabilityChart {
  margin-left: -50px;
}
@media screen and (max-width: 1000px) {
  canvas.probabilityChart {
    margin-left: -610px;
  }
}

.commentReadButton button {
  padding: 2px;
}
img.commentReadButton {
  width: 24px;
  height: auto;
}

table.logs-errorsTable {
  max-width: 720px;
  text-align: center;
}

table.logs-hurstTable {
  max-width: 720px;
  text-align: center;
}

table.thf-logs {
  max-width: 930px;
  text-align: center;
}

table.option-pricing {
  min-width: 1000px;
}

table.top10s {
  min-width: 380px;
}

.toddTop5List {
  display: inline-block;
  margin-bottom: 24px;
}

/* ----------------------------------------------------------------------------------------- */
/* Cut off for navigation elements BURGER_AT_WIDTH */
@media screen and (max-width: 1300px) {
  .c_Heatmap-container {
    display: flex!important;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .c_Heatmap-calls-puts {
    margin-left: 0px;
    justify-content: center;
  }
}
