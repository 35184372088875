.todd-emailContent {
  outline-style: double;
  outline-width: 3px;
  margin: 24px;
}

.toddEmailRead {
  width: 100%;
  height: 520px;
  margin-bottom: -6px;
}

.todd-emailLoading {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  outline-style: double;
  outline-width: 3px;
  margin: 24px;
  height: 630px;
}

table.toddEmail {
  width: 100%;
  max-width: max-content;
}
table.toddEmail, table.toddEmail thead tr th {
  border-collapse: separate;
  border-spacing: 2px 1px;
}
table.toddEmail tbody {
  height: fit-content!important;
  display: block;
  overflow: auto;
}
table.toddEmail thead, table.toddEmail tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  text-align: center;
}
table.toddEmail thead {
  width: fit-content!important;
  min-width: 100%;
}
