li.regime {
  text-align: left;
}
li.regime>div {
  display: flex;
}
li.regime>div>div {
  width: 22%;
}
li.regime>div.trade-details {
  margin-left: 30px;
}
li.regime>div.trade-details .trade-title {
  font-weight: bold;
}
