.site-nav {
  display: inline-flex;
  position: absolute;
  left: 98px;
  max-width: fit-content;
  height: 100%;
  vertical-align: middle;
  padding-top: 3px;
}

.navMenuBar {
  height: 64px;
}

.site-nav a {
  display: inline!important;
}

.site-nav img {
  width: auto;
  max-height: 35px;
}

.site-nav img.ui.image {
  display: inline;
}

.personalized-nav {
  margin-left: auto;
}

.personalized-nav ul {
  display: flex;
  padding: 0;
}

.personalized-nav li {
  list-style-type: none;
  margin: 0 2px;
}

.personalized-nav li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.navBurger {
  display: none;
  position: absolute;
  top: 50%;
  right: 25px;
  width: 40px;
  height: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  transform: translateY(-50%);
  border: 0;
  cursor: pointer;
}

span.navCenter {
  position:absolute;
  top:2px;
  left:50%;
  transform:translateX(-50%);
}

span.navCenter img.ui.image {
  margin-right: 12px;
}

/* ----------------------------------------------------------------------------------------- */
/* Cut off for navigation elements BURGER_AT_WIDTH */
@media screen and (max-width: 1300px) {
  .navBurger {
    display: block;
  }

  .site-nav a {
    padding-inline: 6px!important;
  }

  .personalized-nav ul {
    flex-direction: column;
    position: absolute;
    top: 46px;
    right: 0;
    width: 100%;
    max-width: 460px;
    height: calc(100vh - 46px);
    display: none;
  }

  .personalized-nav li {
    text-align: center;
    margin: 0;
  }

  .personalized-nav li a {
    width: 100%;
    padding: 1.5rem 0;
  }

  .personalized-nav.expanded ul {
    display: block;
  }

  span.navCenter {
    right: 64px;
    transform: none;
    left: revert;
    padding-right: 12px;
  }

  .site-nav .item {
    width: 55px !important;
  }

  .ui.stackable.menu .item {
    width: inherit !important;
  }
}
